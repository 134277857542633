import React from 'react';

const IconLoader = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 15" fill="none">
    <title>Loader Logo</title>
    <path
      d="M0.234375 9.34659L8.23438 12.8693V10.5682L2.83097 8.39773V8.29545L8.23438 6.125V3.82386L0.234375 7.34659V9.34659ZM13.1264 13.1648C14.4162 13.1648 15.2514 12.6023 15.6776 11.7898H15.7457V13H18.0412V7.11364C18.0412 5.03409 16.2798 4.15909 14.3366 4.15909C12.2457 4.15909 10.8707 5.15909 10.5355 6.75L12.7741 6.93182C12.9389 6.35227 13.456 5.92614 14.3253 5.92614C15.1491 5.92614 15.6207 6.34091 15.6207 7.05682V7.09091C15.6207 7.65341 15.0241 7.72727 13.5071 7.875C11.7798 8.03409 10.2287 8.61364 10.2287 10.5625C10.2287 12.2898 11.4616 13.1648 13.1264 13.1648ZM13.8196 11.4943C13.0753 11.4943 12.5412 11.1477 12.5412 10.483C12.5412 9.80114 13.1037 9.46591 13.956 9.34659C14.4844 9.27273 15.348 9.14773 15.6378 8.95455V9.88068C15.6378 10.7955 14.8821 11.4943 13.8196 11.4943ZM19.8736 13H27.2088V11.0682H23.0497V11.0057L27.0611 5.84659V4.27273H20.0213V6.20455H24.1463V6.26705L19.8736 11.5568V13ZM34.1278 0.818181H32.0426L28.2926 14.75H30.3778L34.1278 0.818181ZM43.7656 9.34659V7.34659L35.7656 3.82386V6.125L41.169 8.29545V8.39773L35.7656 10.5682V12.8693L43.7656 9.34659Z"
      fill="black"
    />
  </svg>
);

export default IconLoader;
